import React, {useContext}  from 'react';
import RosterCheckById from '../rosterchecks/roster_check_by_id.js'
import RosterLockById from '../rosterlocks/roster_lock_by_id.js'
import { UserContext } from '../../App';

import { search_icon } from '../iconography/icons.js';

import './homepage.css'


function Home() { 
  const { current_user } = useContext(UserContext);
  return ( current_user && 
       <div className="site-welcome-message"> 
            { current_user && <h4>Welcome { current_user.preferred_username }!</h4> }
            <p></p>
              <div className="object-descriptions">

              <div className="locks-description description">
                <div>
                  <a href="/rosterlocks/search" style={{textDecoration:'none'}}>
                    <h4>Roster Locks</h4>
                  </a>
                  <hr />
                </div>
                <div>
                <span>Quick View</span> 
                <RosterLockById mode="large"/>
                </div>
                <div className="description-footer">
                <a href="/rosterlocks/search">{search_icon()}Search Locks</a>
                </div>
              </div>



              <div className="checks-description description">
                <div>
                  <a href="/rosterchecks/search" style={{textDecoration:'none'}}>
                    <h4>Roster Checks</h4>
                  </a>
                  <hr />
                </div>
                <div>
                  <span>Quick View</span>
                  <RosterCheckById mode="large"/>
                </div>
                <div className="description-footer">
                  <a href="/rosterchecks/search">{search_icon()}Search Checks</a>
                </div>
              </div>

            </div>

      </div> 
    ); 
} 
export default Home; 