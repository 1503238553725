import React, {useContext}  from 'react';
import { useLocation } from 'react-router';
import { UserContext } from '../../App';
import { Dropdown, Button } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import RosterCheckById from '../rosterchecks/roster_check_by_id.js'
import RosterLockById from '../rosterlocks/roster_lock_by_id.js'

import { search_icon } from '../iconography/icons.js';

import './navigation.css'

import Nav from 'react-bootstrap/Nav';

function SiteSidebarNav(props) {
  const loc = useLocation();
  const { current_user } = useContext(UserContext);

  return (
    <Nav defaultActiveKey="/home" className="flex-column nav-content">
      <div className="site-branding">
        <div onClick={props.onToggleNav} id="nav_toggle_button" className="show-nav-button">
          <svg version="1.1"xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 124 124">
            <g>
              <path d="M112,6H12C5.4,6,0,11.4,0,18s5.4,12,12,12h100c6.6,0,12-5.4,12-12S118.6,6,112,6z"/>
              <path d="M112,50H12C5.4,50,0,55.4,0,62c0,6.6,5.4,12,12,12h100c6.6,0,12-5.4,12-12C124,55.4,118.6,50,112,50z"/>
              <path d="M112,94H12c-6.6,0-12,5.4-12,12s5.4,12,12,12h100c6.6,0,12-5.4,12-12S118.6,94,112,94z"/>
            </g>
          </svg>
        </div>
        <a href="/" style={{ textAlign:'right', width:'100%', display:'flex', alignItems:'end', flexDirection:'column', paddingRight:'10px' }}>
          <img src="/images/fantasy_lock.png" width="100%" alt="Fantasy Lock by IC360" />
        </a>
      </div>
      {current_user && <div className="site-profile">
        <Dropdown as={ButtonGroup}>
          <Button variant="primary">{ current_user.preferred_username }</Button>

          <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
          <Dropdown.Menu>
          <Dropdown.Item href="/current_user/profile">Profile</Dropdown.Item>
          <Dropdown.Item href="/current_user/settings">Settings</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="/logout">Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>}
      
      {current_user && loc.pathname !== '/' &&
        <div className="site-tiles">
          <div className="mt-3" style={{ textAlign: 'left' }}>
            
              <div className="navigation-tile check-tile">
                <a href="/rosterchecks/search"><h4>Roster Checks&nbsp;&nbsp;{search_icon()}</h4></a>
                <RosterCheckById />
              </div>
            
            
              <div className="navigation-tile lock-tile">
                <a href="/rosterlocks/search"><h4>Roster Locks&nbsp;&nbsp;{search_icon()}</h4></a>
                <RosterLockById />
              </div>
            

          </div>
        </div>

      }
      

    </Nav>
  );
}
  
export default SiteSidebarNav;