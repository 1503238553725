import React, {useState} from "react";
import RosterCheckList from "../rosterchecks/rostercheck-list";
import { Container, Row, Col, Accordion } from "react-bootstrap";


/*

No parameters in query string.
Build Search fields and criteria here with a form


{
  "licenseKey": "eFmPc4fjzb8uqQZ9M5yEGL",
  "customer_id": "101",
  
  "scoring_period_id": "",
  "subscoring_period_id": "",
  
  "roster_lock_id": 0,
  
  "game_league_id":"MLB",
  "game_event_id":"",
  
  "contest_team_id": "212986",
  "contest_league_id": "",
  "contest_event_id": "",
  
  "check_status": "SUCCESS"
}  

*/

function RosterChecksSearch(props) {
    const [scoring_period_id, set_scoring_period_id] = useState("");
    const [subscoring_period_id, set_subscoring_period_id] = useState("");

    const [game_league_id, set_game_league_id] = useState("");
    const [game_event_id, set_game_event_id] = useState("");

    const [roster_lock_id, set_roster_lock_id] = useState("");

    const [contest_team_id, set_contest_team_id] = useState("");
    const [contest_league_id, set_contest_league_id] = useState("");
    const [contest_event_id, set_contest_event_id] = useState("");

    const [check_status, set_check_status] = useState("")

    const [query_complete, set_query_complete] = useState(false);

    const updateSearch = (change) => {
        set_query_complete(false)
        const update_map = {
            scoring_period_id : set_scoring_period_id,
            subscoring_period_id : set_subscoring_period_id,
            game_league_id : set_game_league_id,
            game_event_id : set_game_event_id,
            roster_lock_id : set_roster_lock_id,
            contest_team_id : set_contest_team_id,
            contest_league_id : set_contest_league_id,
            contest_event_id : set_contest_event_id,
            check_status : set_check_status
        }
        let n = change.target.name;
        let v = change.target.value;
        if(n === 'roster_lock_id') {
            v = (isNaN(parseInt(v))) ? null : parseInt(v);
        }
        update_map[n](v)
    }
    const validate_search_props = () => {
        if(contest_team_id+game_event_id+roster_lock_id !== ""){
            set_query_complete(true);
        }
    }

    return ( 
        <div className="roster-checks-page"> 

            <h2>Roster Check Search</h2>

            <form>
            <Container>
                <Row className="mb-3">
                    <Col>
                        <label htmlFor="roster_lick_id">Roster Lock ID</label>
                        <input id="roster_lock_id" className="form-control" type="number" name="roster_lock_id" value={roster_lock_id} onChange={updateSearch} />
                    </Col>
                    <Col>
                        <label htmlFor="roster_lick_id">Check Result</label>
                        <select name="check_status" value={check_status} aria-label="Default select example" className="form-select"  onChange={updateSearch}>
                            <option value="">ALL</option>
                            <option value="FAILURE">FAILURE</option>
                            <option value="SUCCESS">SUCCCESS</option>
                        </select>
                    </Col>
                </Row>
            </Container>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Fantasy Game Properties</Accordion.Header>
                        <Accordion.Body>
                            <Container className="p-4">
                                <Row>
                                    <Col className="w-50">
                                    <h5>GAME</h5>
                                    <div className="mb-3">
                                        <label htmlFor="game_league_id">Game League ID</label>
                                        <input id="game_league_id" className="form-control" type="text" name="game_league_id" value={game_league_id} onChange={updateSearch}/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="game_event_id">Game Event ID</label>
                                        <input id="game_event_id" className="form-control" type="text" name="game_event_id"  value={game_event_id}  onChange={updateSearch} />
                                    </div>

                                    </Col>
                                    <Col className="w-50">
                                    <h5>CONTEST</h5>
                                    <div className="mb-3">
                                        <label htmlFor="contest_team_id">Contest Team ID</label>
                                        <input id="contest_team_id" className="form-control" type="text" name="contest_team_id" value={contest_team_id}  onChange={updateSearch} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="contest_league_id">Contest League ID</label>
                                        <input id="contest_league_id" className="form-control" type="text" name="contest_league_id" value={contest_league_id} onChange={updateSearch} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="contest_event_id">Contest Event ID</label>
                                        <input id="contest_event_id" className="form-control" type="text" name="contest_event_id" value={contest_event_id} onChange={updateSearch} />
                                    </div>
                                    <Container>
                                        <Row>
                                            <Col className="w-50">
                                                <div className="mb-3">
                                                    <label htmlFor="scoring_period_id">Scoring Period ID</label>
                                                    <input id="scoring_period_id" className="form-control" type="text" name="scoring_period_id" value={scoring_period_id} onChange={updateSearch} />
                                                </div>
                                            </Col>
                                            <Col className="w-50">
                                                <div className="mb-3">
                                                    <label htmlFor="subscoring_period_id">SubScoring Period ID</label>
                                                    <input id="subscoring_period_id" className="form-control" type="text" name="subscoring_period_id" value={subscoring_period_id} onChange={updateSearch} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Container>
                    <Row className="mb-3 mt-3">
                        <button className="btn-lg btn btn-primary" onClick={(e) => {e.preventDefault(); validate_search_props(); return false;}}>Get Roster Checks</button>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        {query_complete && 
                            <RosterCheckList 
                                client_id={props.userSettings.clientID}
                                scoring_period_id={scoring_period_id}
                                subscoring_period_id={subscoring_period_id}
                                game_league_id={game_league_id} 
                                game_event_id={game_event_id} 
                                roster_lock_id={roster_lock_id}                        
                                contest_team_id={contest_team_id}
                                contest_league_id={contest_league_id}
                                contest_event_id={contest_event_id}
                                check_status={check_status}  />
                        }
                        {!query_complete && 
                            <div>No Query Selected</div>
                        }
                    </Row>
                </Container>
            </form>
        </div> 
  ); 
} 
export default RosterChecksSearch; 
