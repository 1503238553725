import React  from 'react';
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import RosterCheckCompare from './rostercheck-compare';



export default function RosterCheckById(props) {
    const [modalOpen, showModal] = React.useState(false);
    const [roster_check_id, set_roster_check_id] = React.useState("")

    const handleClose = () => showModal(false);
    const handleShow = () => {
        showModal(true);
    }

    return (
      <div>
        <Container>
            <Row className="id-search">
                <Col style={{paddingLeft:0}}>
                <input type="text" value={roster_check_id} onChange={e => set_roster_check_id(e.target.value)} name="roster_check_by_id" placeholder="Roster Check ID" />
                </Col>
                <Col>
                    <Button className="btn-sm" onClick={handleShow}>VIEW</Button>
                </Col>
            </Row>
        </Container>
        <Modal dialogClassName="roster-compare-modal" show={modalOpen} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Roster Compare</Modal.Title>
          </Modal.Header>
            <RosterCheckCompare check_id={parseInt(roster_check_id)} />
        </Modal>
      </div>
    )
  }