import React  from "react";
import { gql, useQuery } from "@apollo/client"
import { Container, Row, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table"
import { roster_lock_icon } from '../iconography/icons'
import ReactDiffViewer from 'react-diff-viewer-continued';

export const GET_CHECK_QUERY = gql`
query full_check($licenseKey: String!, $rosterCheckId: Int!) {
    getRosterChecks(getRosterChecksInput: {
      licenseKey:$licenseKey
      optRosterCheckId: $rosterCheckId
    }) {
      rosterChecks {
        rosterLock {
          rosterLockId
          customerId
          rosterLockDataString
          contestEventId
          gameEventId
        }
        rosterCheckId
        rosterCheckReason
        rosterCheckDataString
        rosterCheckTimestamp
        checkStatus
        checkDetailString
        rosterCheckDataFormat
      }
    }
  }`


export default function RosterCheckCompare(props) {

    const {
      data,
      loading,
      error
    } = useQuery(GET_CHECK_QUERY, {
      variables: {
        licenseKey:   window.env.FANTASY_LOCK_KEY, 
        rosterCheckId : props.check_id
      }})
    
    const roster_check = data?.getRosterChecks.rosterChecks;
    if (loading) return <p>Loading Roster Check...</p>
    if (error) return <p>{error.message}</p> 

    if(!roster_check[0]){
      return (<div>No Roster Check</div>)
    }

    let lock_roster = JSON.parse(roster_check[0].rosterLock.rosterLockDataString);
    let check_roster = JSON.parse(roster_check[0].rosterCheckDataString);
    let lock_event_id = roster_check[0].rosterLock.gameEventId

    let lock_roster_string = JSON.stringify(lock_roster, null, 2);
    let check_roster_string =  JSON.stringify(check_roster, null, 2);

    return (
      <div>
        {roster_check[0].rosterCheckDataFormat === 'ROSTER_DATA_FORMAT_V2' &&
        <Container style={{maxHeight:'80vh', overflowY:'auto'}}>
          <Row>
            <Col><h5 className="text-center font-weight-bold"> Lock </h5></Col>
            <Col><h5 className="text-center font-weight-bold"> Check </h5></Col>
          </Row>
        </Container>
        }
        <Container style={{maxHeight:'80vh', overflowY:'auto'}}>

          {roster_check[0].rosterCheckDataFormat === 'ROSTER_DATA_FORMAT_V2' &&
          <Row>
            <Col>
              <div>
              <Table>
              <tbody>
                {lock_roster.map((player, i) => (
                  <tr key={i} className={ (parseInt(player.event_id) === parseInt(lock_event_id)) ? 'current':'' }>
                    <td style={{ color:'green' }}>{roster_lock_icon(player.is_locked)}</td>
                    <td>{player.event_id}</td>
                    <td>{player.player_id}</td>
                    <td>{player.team}</td>
                    <td>{player.pos}</td>
                  </tr>
                ))}
                </tbody>
                </Table>
              </div>
            </Col>
            <Col>
              <div>
              <Table>
              <tbody>
                {check_roster.map((player, i) => (
                  <tr key={i} className={ (parseInt(player.event_id) === parseInt(lock_event_id)) ? 'current':'' }>
                    <td style={{ color:'green' }}>{roster_lock_icon(player.is_locked)} </td>
                    <td>{player.event_id}</td>
                    <td>{player.player_id}</td>
                    <td>{player.team}</td>
                    <td>{player.pos}</td>
                  </tr>
                ))}
                </tbody>
                </Table>
              </div>
            </Col>
          </Row>}
          {roster_check[0].rosterCheckDataFormat !== 'ROSTER_DATA_FORMAT_V2' && 
          <Row>
            <Col>
              <ReactDiffViewer oldValue={lock_roster_string} newValue={check_roster_string} splitView={false} />
            </Col>
          </Row>
          }
        </Container>
      </div>
    )
  }