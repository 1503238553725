import React  from 'react';
import { gql, useQuery } from "@apollo/client"
import {Table, Modal, Button} from 'react-bootstrap';
import RosterLockView from './rosterlock-view';



export const GET_LOCKS_QUERY = gql`
query locks (
    $licenseKey:String!
    $customer_id:String!

    $scoring_period_id:String
    $subscoring_period_id:String

    $game_league_id: String
    $game_event_id: String

    $contest_team_id:String
    $contest_league_id: String
    $contest_event_id: String

    $start_time: DateTime
    $end_time: DateTime

    $cursor: Int!
    $page_limit: Int!

  ){
  getRosterLocks(getRosterLocksInput: {
    licenseKey:$licenseKey
    optCustomerId:$customer_id
    
    optScoringPeriodId:$scoring_period_id
    optSubScoringPeriodId:$subscoring_period_id
    
    optGameLeagueId:$game_league_id
    optGameEventId: $game_event_id
    
    optContestTeamId:$contest_team_id
    optContestLeagueId:$contest_league_id
    optContestEventId: $contest_event_id

    optStartTime: $start_time
    optEndTime: $end_time

    optPagination: {
      cursor: $cursor
      limit:$page_limit
    }
  }) {
    rosterLocks {
      customerId
      contestEventId
      rosterLockTimestamp
      rosterLockId
      contestTeamId
      rosterLockReason
      scoringPeriodId
      gameEventId
      subScoringPeriodId
      gameLeagueId
      contestLeagueId
      uniqueClientReferenceId
    }
    cursor
  }
}`

const time_stamp = (ts) => {
  let d = new Date(ts);
  return d.toLocaleString();
}
export default function RosterLockList(props) {
    const [modalOpen, showModal] = React.useState(false);
    const [activeRosterLockId, setActiveRosterLockId] = React.useState(1);
    const handleClose = () => showModal(false);
    const page_limit = props.page_limit || 100
    const handleShow = (check_id) => {
        setActiveRosterLockId(check_id);
        showModal(true);
    }

    let v = {
      licenseKey            : window.env.FANTASY_LOCK_KEY, 
      customer_id           : props.client_id,
      
      scoring_period_id     : props.scoring_period_id,
      subscoring_period_id  : props.subscoring_period_id,
      game_league_id        : props.game_league_id,
      game_event_id         : props.game_event_id,
      
      contest_team_id       : props.contest_team_id,
      contest_league_id     : props.contest_league_id,
      contest_event_id      : props.contest_event_id,
      cursor                : (props.cursor || 0),
      page_limit            : page_limit

    }

    if (props.start_time && props.start_time !== "") {
      v["start_time"] = props.start_time
    }
    if (props.end_time && props.end_time !== "") {
      v["end_time"] = props.end_time
    }

    const {
        data,
        loading,
        error
      } = useQuery(GET_LOCKS_QUERY, {
        variables: v})
      
      const locks = data?.getRosterLocks.rosterLocks
      const new_cursor = data?.getRosterLocks.cursor
      console.info(new_cursor)

      if (loading) return <p>Loading Roster Locks...</p>
      if (error) return <p>{error.message}</p> 

      var sorted_locks = locks.slice(); //.sort(entries_by_date);
      const next = () => {
        props.set_cursor(new_cursor);
      }
    return (
      <div>
        <Table striped bordered hover>
            <thead>
                <tr>
                <th>ID</th>
                <th>Reference</th>
                <th>Reason</th>
                <th>Event ID</th>
                <th>Scoring Period</th>
                <th>Time Stamp</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {sorted_locks.map((lock) => (
                    <tr key={lock.rosterLockId}>
                        <td>{lock.rosterLockId}</td>
                        <td>{lock.uniqueClientReferenceId}</td>
                        <td>{lock.rosterLockReason}</td>
                        <td>{lock.contestEventId}</td>
                        <td>{lock.scoringPeriodId} : {lock.subScoringPeriodId}</td>
                        <td>{time_stamp(lock.rosterLockTimestamp)}</td>
                        <td>
                            <Button className="btn-sm" onClick={() => {handleShow(lock.rosterLockId);}}>Review</Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
        <Modal dialogClassName="roster-lock-modal" show={modalOpen} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Roster Lock</Modal.Title>
          </Modal.Header>
            <RosterLockView lock_id={activeRosterLockId} />
        </Modal>
        <div style={{textAlign:'right'}}>
          {new_cursor !== 0 && <Button onClick={() => {next()}}>Next {page_limit}</Button>}
        </div>
      </div>
    )
  }