import React, {useState} from "react";
import RosterLockList from "../rosterlocks/rosterlock-list";
import { Container, Row, Col, Accordion } from "react-bootstrap";


/*

No parameters in query string.
Build Search fields and criteria here with a form


{
  "licenseKey": "eFmPc4fjzb8uqQZ9M5yEGL",
  "customer_id": "101",
  
  "scoring_period_id": "",
  "subscoring_period_id": "",
  
  "roster_lock_id": 0,
  
  "game_league_id":"MLB",
  "game_event_id":"",
  
  "contest_team_id": "212986",
  "contest_league_id": "",
  "contest_event_id": "",
  
}  


optUniqueClientReferenceId: String

optScoringPeriodId: String
optSubScoringPeriodId: String
optGameLeagueId: String
optGameEventId: String
optContestLeagueId: String
optContestEventId: String
optContestTeamId: String
optCustomerId: String

opsStartTime: DateTime
optEndTime: DateTime

*/

let now = new Date();
let past = new Date();


const now_string = now.toISOString();
past.setDate(past.getDate() - 1);
const one_day_ago = past.toISOString();
const last_24_hours = {start:one_day_ago, end:now_string};

function RosterLocksSearch(props) {
    const [scoring_period_id, set_scoring_period_id] = useState("");
    const [subscoring_period_id, set_subscoring_period_id] = useState("");

    const [game_league_id, set_game_league_id] = useState("");
    const [game_event_id, set_game_event_id] = useState("");

    const [contest_team_id, set_contest_team_id] = useState("");
    const [contest_league_id, set_contest_league_id] = useState("");
    const [contest_event_id, set_contest_event_id] = useState("");

    const [day, set_day] = useState(now_string.split('T')[0]);
    const [start_time, set_start_time] = useState(last_24_hours.start);
    const [end_time, set_end_time] = useState(last_24_hours.end);
    const [time, set_time] = useState('00:00');

    const [query_complete, set_query_complete] = useState(false);

    const [cursor, set_cursor] = useState(0);

    const set_time_frame = function(v){
        // v in number of days
        let d = new Date(start_time);
        d.setDate(d.getDate() + v);
        set_end_time(d.toISOString());
    }
    const updateSearch = (change) => {
        set_query_complete(false)
        const update_map = {
            scoring_period_id       : set_scoring_period_id,
            subscoring_period_id    : set_subscoring_period_id,
            game_league_id          : set_game_league_id,
            game_event_id           : set_game_event_id,
            contest_team_id         : set_contest_team_id,
            contest_league_id       : set_contest_league_id,
            contest_event_id        : set_contest_event_id,
            end_time                : set_end_time,
            time_frame              : set_time_frame,
            day                     : set_day,
            time                    : set_time
        }
        let n = change.target.name;
        let v = change.target.value;
        update_map[n](v);
        let st = day+'T'+time+':00Z';
        set_start_time(st);
    }
    const validate_search_props = () => {

        if(contest_team_id+game_event_id !== ""){
            set_query_complete(true);
        } else if (start_time+end_time !== "") {
            set_query_complete(true);
        }
    }

    return ( 
        <div className="roster-locks-page"> 

            <h2>Roster Lock Search</h2>

            <form>

            <Accordion defaultActiveKey="0">

                <Accordion.Item eventKey="0">
                    <Accordion.Header>Time Based Search</Accordion.Header>
                    <Accordion.Body>
                        <Container>
                            <Row className="mb-3">
                                <Col>
                                    <label>Start Date</label>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>  
                                    <input className="form-control" type="date" name="day" id="day" value={day}  onChange={updateSearch} />
                                </Col>
                                <Col>
                                    <input className="form-control" type="time" name="time" id="time" value={time}  onChange={updateSearch} />
                                </Col>
                                <Col>
                                    <input type="hidden" name="end_time" id="end_time" value={end_time} onChange={updateSearch} />
                                    <select className="form-control" name="time_frame" id="time_frame" onChange={updateSearch}>
                                        <option value="60">No Limit</option>
                                        <option value="1">One Day</option>
                                        <option value="7">One Week</option>
                                    </select>
                                </Col>
                            </Row>
                        </Container>


                    </Accordion.Body>
                </Accordion.Item>
                 
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Fantasy Game Properties</Accordion.Header>
                        <Accordion.Body>
                            <Container className="p-4">
                                <Row>
                                    <Col className="w-50">
                                    <h5>GAME</h5>
                                    <div className="mb-3">
                                        <label htmlFor="game_league_id">Game League ID</label>
                                        <input id="game_league_id" className="form-control" type="text" name="game_league_id" value={game_league_id} onChange={updateSearch}/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="game_event_id">Game Event ID</label>
                                        <input id="game_event_id" className="form-control" type="text" name="game_event_id"  value={game_event_id}  onChange={updateSearch} />
                                    </div>

                                    </Col>
                                    <Col className="w-50">
                                    <h5>CONTEST</h5>
                                    <div className="mb-3">
                                        <label htmlFor="contest_team_id">Contest Team ID</label>
                                        <input id="contest_team_id" className="form-control" type="text" name="contest_team_id" value={contest_team_id}  onChange={updateSearch} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="contest_league_id">Contest League ID</label>
                                        <input id="contest_league_id" className="form-control" type="text" name="contest_league_id" value={contest_league_id} onChange={updateSearch} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="contest_event_id">Contest Event ID</label>
                                        <input id="contest_event_id" className="form-control" type="text" name="contest_event_id" value={contest_event_id} onChange={updateSearch} />
                                    </div>
                                    <Container>
                                        <Row>
                                            <Col className="w-50">
                                                <div className="mb-3">
                                                    <label htmlFor="scoring_period_id">Scoring Period ID</label>
                                                    <input id="scoring_period_id" className="form-control" type="text" name="scoring_period_id" value={scoring_period_id} onChange={updateSearch} />
                                                </div>
                                            </Col>
                                            <Col className="w-50">
                                                <div className="mb-3">
                                                    <label htmlFor="subscoring_period_id">SubScoring Period ID</label>
                                                    <input id="subscoring_period_id" className="form-control" type="text" name="subscoring_period_id" value={subscoring_period_id} onChange={updateSearch} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Container>
                    <Row className="mb-3 mt-3">
                        <button className="btn-lg btn btn-primary" onClick={(e) => {e.preventDefault(); validate_search_props(); return false;}}>Get Roster Locks</button>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        {query_complete && 
                            <RosterLockList 
                                client_id={props.userSettings.clientID}
                                scoring_period_id={scoring_period_id}
                                subscoring_period_id={subscoring_period_id}
                                game_league_id={game_league_id} 
                                game_event_id={game_event_id}                        
                                contest_team_id={contest_team_id}
                                contest_league_id={contest_league_id}
                                contest_event_id={contest_event_id} 
                                start_time={start_time}
                                end_time={end_time}
                                set_cursor={set_cursor}
                                cursor={cursor}/>
                        }
                        {!query_complete && 
                            <div>No Query Selected</div>
                        }
                    </Row>
                </Container>
            </form>
        </div> 
  ); 
} 
export default RosterLocksSearch; 
