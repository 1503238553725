import React, { useState, useEffect, useContext }  from 'react';
import { UserContext } from '../../App';

function CurrentUser(props) {
    const { current_user, setUser } = useContext(UserContext);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
    fetch(`/current_user`)
        .then(response => {
        if (response.ok) return response.json();
        throw response;
        }).then(json => {
            setUser(json);
        }).catch(err => {
            console.error(err);
            setUser(null);
            setError(err);
        }).finally(() => {
            setLoading(false);
        });
    },[]);

    if (loading) return "Loading...";
    if (error) return 'Error';

    if(!current_user.resource_access.fantasylock){
        return 'LOGGED IN : No Access To Fantasy Lock';
    }
    return  (<div></div>)
}

export default CurrentUser;