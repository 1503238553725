import React  from 'react';
import { gql, useQuery } from "@apollo/client"
import Table from 'react-bootstrap/Table';
import { Button, Modal } from 'react-bootstrap';
import RosterCheckCompare from './rostercheck-compare';

import { check_status_icon } from '../iconography/icons';
/*
ALL PARAMETERS IN CHECK SEARCH

optCustomerId: String
optContestTeamId: String
optScoringPeriodId: String
optSubScoringPeriodId: String

optRosterLockId: Int
optCheckStatus: CheckStatus
optGameLeagueId: String
optGameEventId: String
optContestLeagueId: String
optContestEventId: String


UNIQUE ITEM RESULTS
optUniqueClientReferenceId: String
optRosterCheckId: Int

*/

const GET_CHECKS_QUERY = gql`
  query checks(
    $licenseKey:String!
    $customer_id:String!
    
    $scoring_period_id:String
    $subscoring_period_id:String

    $roster_lock_id: Int

    $game_league_id: String
    $game_event_id: String

    $contest_team_id:String
    $contest_league_id: String
    $contest_event_id: String

    $check_status: CheckStatus
  ) {
  getRosterChecks(getRosterChecksInput: {
    licenseKey:$licenseKey
    optCustomerId:$customer_id
    
    optScoringPeriodId:$scoring_period_id
    optSubScoringPeriodId:$subscoring_period_id
    
    optRosterLockId:$roster_lock_id
    
    optGameLeagueId:$game_league_id
    optGameEventId: $game_event_id
    
    optContestTeamId:$contest_team_id
    optContestLeagueId:$contest_league_id
    optContestEventId: $contest_event_id
    
    optCheckStatus:$check_status
    
  }) {
    rosterChecks {
        rosterLock {
            rosterLockId
            contestEventId
            gameEventId
            contestLeagueId
            gameLeagueId
        }
        rosterCheckId
        rosterCheckReason
        rosterCheckTimestamp
        checkStatus
        checkDetailString
    }
  }
}`

const time_stamp = (ts) => {
  let d = new Date(ts);
  return d.toLocaleString();
}

export default function RosterCheckList(props) {
    const [modalOpen, showModal] = React.useState(false);
    const [activeRosterCheckId, setActiveRosterCheckId] = React.useState(1);
    const handleClose = () => showModal(false);
    const handleShow = (check_id) => {
        setActiveRosterCheckId(check_id);
        showModal(true);
    }
    let v = {
      licenseKey            : window.env.FANTASY_LOCK_KEY, 
      customer_id           : props.client_id,
      
      scoring_period_id     : props.scoring_period_id,
      subscoring_period_id  : props.subscoring_period_id,
      game_league_id        : props.game_league_id,
      game_event_id         : props.game_event_id,
      
      contest_team_id       : props.contest_team_id,
      contest_league_id     : props.contest_league_id,
      contest_event_id      : props.contest_event_id,

      roster_lock_id        : (parseInt(props.roster_lock_id) || null)
    }
    if (['SUCCESS','FAILURE'].includes(props.check_status)) {
      v.check_status = props.check_status
    }
    const {
        data,
        loading,
        error
      } = useQuery(GET_CHECKS_QUERY, {
        variables: v
      })
      
      const checks = data?.getRosterChecks.rosterChecks;

      const entries_by_date = (a,b) => {
        if (a.rosterCheckTimestamp === b.rosterCheckTimestamp) return 0;
        return (a.rosterCheckTimestamp < b.rosterCheckTimestamp) ? -1 : 1;
      }

      if (loading) return <p>Loading Roster Checks...</p>
      if (error) return <p>{error.message}</p> 

      var sorted_checks = checks.slice().sort(entries_by_date);

    return (
      <div>
        <Table striped bordered hover>
            <thead>
                <tr>
                <th style={{width:'30px'}}></th>
                <th>Reason</th>
                <th>Roster Lock ID</th>
                <th>Game Event ID</th>
                <th>Time Stamp</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {sorted_checks.map((check) => (
                    <tr key={check.rosterCheckId}>
                        <td>{check_status_icon(check.checkStatus)}</td>
                        <td>{check.rosterCheckReason}</td>
                        <td>{check.rosterLock.rosterLockId}</td>
                        <td>{check.rosterLock.gameEventId}</td>
                        <td>{time_stamp(check.rosterCheckTimestamp)}</td>
                        <td>
                            <Button className="btn-sm" onClick={() => {handleShow(check.rosterCheckId);}}>Review</Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
        <Modal dialogClassName="roster-compare-modal" show={modalOpen} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Roster Compare</Modal.Title>
          </Modal.Header>
            <RosterCheckCompare check_id={activeRosterCheckId} />
        </Modal>
      </div>
    )
  }