import React  from 'react';
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import RosterLockView from './rosterlock-view'




export default function RosterLockById(props) {
    const [modalOpen, showModal] = React.useState(false);
    const [roster_lock_id, set_roster_lock_id] = React.useState("")
    const [roster_lock_reference, set_roster_lock_reference] = React.useState("")

    const handleClose = () => {
      showModal(false);
      set_roster_lock_id("")
      set_roster_lock_reference("")
    }
    const handleShow = () => showModal(true);

    return (
      <div>
        <Container>
            <Row className="id-search">
                <Col style={{paddingLeft:0}}>
                <input type="text" value={roster_lock_id} onChange={e => set_roster_lock_id(e.target.value)} name="roster_lock_by_id" placeholder="Roster Lock ID" />
                </Col>
                <Col>
                    <Button className="btn-sm" onClick={handleShow}>VIEW</Button>
                </Col>
            </Row>
            <Row><Col style={{textAlign:'center', padding:'1em 0'}}>OR</Col></Row>
            <Row className="id-search">
                <Col style={{paddingLeft:0}}>
                <input type="text" value={roster_lock_reference} onChange={e => set_roster_lock_reference(e.target.value)} name="roster_lock_by_reference" placeholder="Roster Lock Reference" />
                </Col>
                <Col>
                    <Button className="btn-sm" onClick={handleShow}>VIEW</Button>
                </Col>
            </Row>
        </Container>
        <Modal dialogClassName="roster-compare-modal" show={modalOpen} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Roster Lock</Modal.Title>
          </Modal.Header>
            <RosterLockView lock_id={parseInt(roster_lock_id)} lock_reference={roster_lock_reference}/>
        </Modal>
      </div>
    )
  }