import React  from "react";
import { gql, useQuery } from "@apollo/client"
import { Container, Row, Col, Button } from "react-bootstrap"
import ReactDiffViewer from 'react-diff-viewer-continued';

const GET_LOCK_QUERY = gql`
query full_lock($licenseKey: String!, $rosterLockId: Int!) {
    getRosterLocks(getRosterLocksInput: {
      licenseKey:$licenseKey
      optRosterLockId: $rosterLockId
    }) {
        rosterLocks {
          rosterLockId
          customerId
          rosterLockTimestamp
          rosterLockDataString
          contestEventId
          gameEventId
        }
    }
    getRosterChecks(getRosterChecksInput: {
        licenseKey:$licenseKey
        optRosterLockId: $rosterLockId
    }) {
        rosterChecks {
          rosterCheckId
        }
    }
  }`


const time_stamp = (ts) => {
    let d = new Date(ts);
    return d.toLocaleString();
  }

export default function RosterLockView(props) {

    const {
      data,
      loading,
      error
    } = useQuery(GET_LOCK_QUERY, {
      variables: {
        licenseKey:   window.env.FANTASY_LOCK_KEY, 
        rosterLockId : parseInt(props.lock_id)
      }})
    
    const roster_lock = data?.getRosterLocks.rosterLocks;
    const checks = data?.getRosterChecks.rosterChecks;
    if (loading) return <p>Loading Roster Lock...</p>
    if (error) return <p>{error.message}</p> 

    if(!roster_lock[0]){
      return (<div>No Roster Lock</div>)
    }
    let lock = roster_lock[0]
    let lock_roster = JSON.parse(lock.rosterLockDataString);

    let lock_roster_string = JSON.stringify(lock_roster, null, 2);
    
    return (
      <div>
        <Container className="p-3" style={{backgroundColor:'#eee', maxHeight:'80vh', overflowY:'auto'}}>
          <Row>
            <Col>
            <strong>LOCK ID :</strong> {props.lock_id}
            </Col>
            <Col>
            <strong>CREATED :</strong> {time_stamp(lock.rosterLockTimestamp)}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
            <strong>CHECKS ({checks.length}) :</strong> {checks.map((check) => (
                    <Button style={{marginRight:'1em'}} className="btn btn-sm">{check.rosterCheckId}</Button>
                ))}
            </Col>
          </Row>
        </Container>
        <Container style={{maxHeight:'70vh', overflowY:'auto'}}>
          <Row>
            <Col>
              <ReactDiffViewer hideLineNumbers={true} showDiffOnly={false} oldValue={lock_roster_string} newValue={lock_roster_string} splitView={false} />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  