
import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom' 
import SiteSidebarNav from './components/navigation/site-navigation';

import Home from './components/pages/home';
import RosterChecksSearch from './components/pages/roster-checks-search';
import RosterLocksSearch from './components/pages/roster-locks-search';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CurrentUser from './components/authentication/current-user';

export const UserContext = React.createContext(null);

function App() {
  // TODO : get client ID from profile at login
  const [clientId, setClientId] = useState('1967617');
  const userSettings = {
    clientID: clientId,
    setClientId
  };
  const [navOpen, setNavOpen] = useState(false);
  const [current_user, setUser] = useState(null);
  const toggleNav = () => {
    setNavOpen(!navOpen)
  }
  return (
    <BrowserRouter>
    <UserContext.Provider value={{ current_user: current_user, setUser: setUser }}>
      <div className="App fullPage" sidebar_open={navOpen ? 'true' : 'false'}>

        <div className="sidenav bg-body-tertiary left-nav-column" >
          <CurrentUser/>
          <SiteSidebarNav onToggleNav={toggleNav}/>
        </div>
        <div className="pageBody">
          
          <Routes>
            <Route path="/" exact element={<Home  userSettings={userSettings}/>} />
            <Route path="/rosterchecks/search/" 
                  exact element={(<RosterChecksSearch userSettings={userSettings}/>)} />
            <Route path="/rosterlocks/search/" 
                  exact element={(<RosterLocksSearch userSettings={userSettings}/>)} />

          </Routes>
          
        </div>
      </div>
    </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App;
